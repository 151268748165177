import React from 'react'
import LayoutLightLifetime from '@components/layouts/light/LayoutLightLifetime'
import SafeWithUsSection from '@sections/pricing/SafeWithUsSection'
import BenefitsSection from '@sections/home/BenefitsSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import LifetimeFAQSection from '@sections/lifetime-deal/LifetimeFAQSection'
import LifetimeHeroSection from '@sections/lifetime-deal/LifetimeHeroSection'
import LifetimeTextVideoSection from '@sections/lifetime-deal/LifetimeTextVideoSection'
import LifetimeCardSection from '@sections/lifetime-deal/LifetimeCardSection'
import LifetimeMoneyBackSection from '@sections/lifetime-deal/LifetimeMoneyBackSection'
import AiAssistanceSection from '@sections/home/AIAssistanceSection'
import RatingSection from '@sections/home/RatingSection'

const Lifetime_Deal = () => {
  return (
    <LayoutLightLifetime pageName="lifetime-deal">
      <LifetimeHeroSection />
      <RatingSection
        sectionPaddingSm="10px 0 10px 0"
        sectionPaddingMd="10px 0 10px 0"
        sectionPaddingXl="10px 0 10px 0"
      />
      <LifetimeCardSection />
      <LifetimeMoneyBackSection
        sectionPaddingSm="5px 0 50px 0"
        sectionPaddingMd="5px 0 50px 0"
        sectionPaddingXl="5px 0 60px 0"
      />
      <LifetimeTextVideoSection />
      <AiAssistanceSection />
      <BenefitsSection />
      <SafeWithUsSection />
      <TestimonialsSection />
      <LifetimeFAQSection />
    </LayoutLightLifetime>
  )
}

export default Lifetime_Deal
