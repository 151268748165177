import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint, transition } from '@configs/utilities'
import iconAvailable from '@assets/images/pricing/planCards/iconAvailable.svg'
import iconNonAvailable from '@assets/images/pricing/planCards/iconNonAvailable.svg'
import SwitchNoOfIdeas from '@components/pages/pricing/switches/SwitchNoOfIdeas'
import Loader from '@components/ui/loaders/Loader'
import { priceAnimation } from '@configs/animations'
import { useWindowSize } from '@configs/getGlobalWindowSize'
import AccordionPlanCardLifetime from '@components/ui/accordions/AccordionPlanCardLifetime'
import iconAi from '@assets/images/pricing/planCards/iconAi.svg'
import { generateAppLink } from '@configs/helpers'

const LifetimePlanCard = ({
  intl,
  slug,
  title,
  description,
  price,
  currency,
  period,
  disabledFeatures,
  noi,
  priceOptions,
  onChangeNoi,
  aiRequests,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allPlanCardFeaturesJson {
        edges {
          node {
            feature
            tooltip
            bold
          }
        }
      }
    }
  `)

  let priceCrossRender = useRef(null)
  let priceCrossLineRender = useRef(null)
  let priceMainRender = useRef(null)
  let priceBilledRender = useRef(null)
  let loaded = price === null ? 'loading' : 'loaded'

  useEffect(() => {
    if (loaded) {
      priceAnimation(priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender)
    }
  }, [loaded])

  const currentWidth = useWindowSize().width

  return (
    <Card>
      <div className="lifetimeCard">
        <TopWrapper>
          <TextWrapper>
            <p className="cardTitle">{title}</p>
            <p className="cardDescription">{description}</p>
          </TextWrapper>
          <SaveUp>{intl.formatMessage({ id: 'miscellaneous.save90' })}</SaveUp>
          <PriceWrapper>
            <div className="withPriceWrapper">
              <div className="allPriceWrapper">
                <div className="price">
                  <div>
                    {price === null ? (
                      <Loader />
                    ) : (
                      <div>
                        <div className="crossPrice" ref={priceCrossRender}>
                          <span className={period !== 'lifetime' ? 'dollarLeftCross' : 'dollarCross'}>{currency}</span>
                          <div className="priceWithoutCurrency">
                            <div className="number">
                              {period === 'annual'
                                ? Math.round((price.replace(',', '') / 12) * 10)
                                : Math.round(price.replace(',', '')) * 9}
                            </div>
                            <div className="crossLine" ref={priceCrossLineRender}></div>
                          </div>
                        </div>
                        <div className="priceMain" ref={priceMainRender}>
                          <span className={period !== 'lifetime' ? 'dollarLeft' : 'dollar'}>{currency}</span>
                          <span className="priceText">
                            {period === 'annual'
                              ? Math.round((price.replace(',', '') / 12) * 10) / 10
                              : Math.round(price.replace(',', ''))}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {price !== null && (
                    <PerMonth className="priceBilledRender" ref={priceBilledRender}>
                      {period !== 'lifetime' ? intl.formatMessage({ id: 'pricing.cards.per' }) : ''}
                    </PerMonth>
                  )}
                </div>
                {price !== null && (
                  <Billed className="priceBilledRender" ref={priceBilledRender}>
                    <p className="lifetime">{intl.formatMessage({ id: 'pricing.cards.billedOneTime' })}</p>
                  </Billed>
                )}
              </div>
              <SwitchPeriodWrapper>
                <PriceSwitchTextWrapper>
                  <span className="darkText">{intl.formatMessage({ id: 'pricing.cards.switchAdd' })}</span>
                  <span className="blueText">
                    <span className="tooltip">{intl.formatMessage({ id: 'pricing.cards.ideasTooltip' })}</span>
                    {intl.formatMessage({ id: 'pricing.cards.ideas' })}
                  </span>
                  <span className="and">&</span>
                  <span className="blueText">
                    <span className="tooltip">{intl.formatMessage({ id: 'pricing.cards.collaboratorsTooltip' })}</span>
                    {intl.formatMessage({ id: 'pricing.cards.collaborators' })}
                  </span>
                  <SwitchNoOfIdeas
                    key={`${slug}-${period}`}
                    value={noi}
                    priceOptions={priceOptions}
                    onChangeNoi={onChangeNoi}
                  />
                </PriceSwitchTextWrapper>
              </SwitchPeriodWrapper>
            </div>
          </PriceWrapper>
        </TopWrapper>

        <BottomWrapper>
          <ButtonWrapper>
            <a
              href={generateAppLink('payment/lifetime-deal', intl.locale, `plan=${slug}&type=${period}&noi=${noi}`)}
              className="blueButton"
            >
              <span>{intl.formatMessage({ id: 'lifetime-deal.cardButtonText' })}</span>
            </a>
          </ButtonWrapper>
          {currentWidth > 768 ? (
            <FeaturesWrapper>
              <p className="featureTitle">{intl.formatMessage({ id: 'pricing.cards.features.title' })}</p>
              {data.allPlanCardFeaturesJson.edges.map((item, index) => {
                const disabled = disabledFeatures.indexOf(index + 1) === -1
                return (
                  <Feature key={index}>
                    {disabled ? (
                      <div className="enabledFeature">
                        <span className="tooltip">{intl.formatMessage({ id: item.node.tooltip })}</span>
                        <img src={iconAvailable} alt="icon" />
                        <span
                          className={
                            item.node.feature === 'pricing.cards.features.aIRequests'
                              ? 'featureText aiFeature'
                              : 'featureText'
                          }
                        >
                          <span>
                            {slug === 'freePlan' && item.node.feature === 'pricing.cards.features.aIRequests'
                              ? 10
                              : null}
                          </span>
                          <span>{item.node.feature === 'pricing.cards.features.aIRequests' ? aiRequests : null}</span>
                          <span className="featureName">{intl.formatMessage({ id: item.node.feature })}</span>
                        </span>
                        {item.node.feature === 'pricing.cards.features.aIRequests' ? (
                          <div className="aIRequestsContainer">
                            <img src={iconAi} className="aiIcon" alt="ai icon" />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="disabledFeature">
                        <img src={iconNonAvailable} alt="" />
                        <span className="featureText">{intl.formatMessage({ id: item.node.feature })}</span>
                      </div>
                    )}
                  </Feature>
                )
              })}
            </FeaturesWrapper>
          ) : (
            <FeaturesWrapper>
              <AccordionPlanCardLifetime
                id="lifetimeCard"
                head={intl.formatMessage({ id: 'pricing.cards.features.title' })}
                text={data.allPlanCardFeaturesJson.edges.map((item, index) => {
                  const disabled = disabledFeatures.indexOf(index + 1) === -1
                  return (
                    <Feature key={index}>
                      {disabled ? (
                        <div className="enabledFeature">
                          <span className="tooltip">{intl.formatMessage({ id: item.node.tooltip })}</span>
                          <img src={iconAvailable} alt="icon" />
                          <span
                            className={
                              item.node.feature === 'pricing.cards.features.aIRequests'
                                ? 'featureText aiFeature'
                                : 'featureText'
                            }
                          >
                            <span>
                              {slug === 'freePlan' && item.node.feature === 'pricing.cards.features.aIRequests'
                                ? 10
                                : null}
                            </span>
                            <span>{item.node.feature === 'pricing.cards.features.aIRequests' ? aiRequests : null}</span>
                            <span className="featureName">{intl.formatMessage({ id: item.node.feature })}</span>
                          </span>
                          {item.node.feature === 'pricing.cards.features.aIRequests' ? (
                            <div className="aIRequestsContainer">
                              <img src={iconAi} className="aiIcon" alt="ai icon" />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="disabledFeature">
                          <img src={iconNonAvailable} alt="" />
                          <span className="featureText">{intl.formatMessage({ id: item.node.feature })}</span>
                        </div>
                      )}
                    </Feature>
                  )
                })}
              />
            </FeaturesWrapper>
          )}
        </BottomWrapper>
      </div>
    </Card>
  )
}

export default injectIntl(LifetimePlanCard)

const Card = styled.div`
  user-select: none;
  display: flex;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: ${breakpoint.sm}) {
    width: 70%;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 276px;
  }

  .lifetimeCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(187, 201, 228, 0.33);
    border-radius: 8px;
    padding: 30px 20px 30px 25px;
    margin-bottom: 20px;
    background: #081e4a;

    @media screen and (min-width: ${breakpoint.md}) {
      padding: 30px 10px 30px 10px;
      margin-bottom: 0;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      padding: 30px 20px 30px 25px;
    }

    .cardDescription {
      min-height: 70px;
    }
  }

  .tooltip {
    position: absolute;
    visibility: hidden;
    width: 90%;
    min-width: 177px;
    background: ${color.white};
    color: ${color.font};
    text-align: left;
    border-radius: 8px;
    padding: 10px 15px;
    z-index: 1;
    bottom: 150%;
    left: 0;
    transition: 0.1s all;
    font-weight: 300 !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }

  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${color.font} transparent transparent transparent;
  }
`

/* -------------------------------------------------- Card Top -------------------------------------------------- */
const TopWrapper = styled.div``

const TextWrapper = styled.div`
  text-align: center;
  color: ${color.white};
  width: 100%;
  max-width: 220px;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 100%;
  }
  .cardTitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
  }
  .cardDescription {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 13px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 14px;
    }
  }
`

const PriceWrapper = styled.div`
  .withPriceWrapper {
    width: 100%;

    .allPriceWrapper {
      min-height: 140px;
      margin-top: 20px;
      margin-bottom: 20px;

      @media screen and (min-width: ${breakpoint.md}) {
        min-height: 120px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media screen and (min-width: ${breakpoint.xl}) {
        min-height: 140px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .price {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${color.yellow};

        .crossPrice {
          visibility: hidden;
          display: flex;
          justify-content: center;
          color: #bbc9e4;
          padding-bottom: 12px;
          font-size: 32px;
          line-height: 20px;

          .priceWithoutCurrency {
            position: relative;
            width: fit-content;

            .number {
              width: fit-content;
            }

            .crossLine {
              position: absolute;
              visibility: hidden;
              width: fit-content;
              top: 35%;
              &:after {
                position: absolute;
                content: '';
                display: block;
                margin: 0 auto;
                height: 2px;
                background: ${color.yellow};
                width: 100%;
                right: 0;
                top: 25%;
              }
            }
          }
        }

        .priceMain {
          visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dollarCross,
        .dollarLeftCross {
          display: contents;
          font-weight: 600;
          font-size: 15px;
          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 10px;
          }
          @media screen and (min-width: ${breakpoint.xl}) {
            font-size: 15px;
          }
        }

        .dollar,
        .dollarLeft {
          font-weight: 700;
          font-size: 25px;
          align-self: end;
        }

        .priceText {
          font-weight: 600;
          font-size: 55px;
          line-height: 55px;
          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 45px;
            line-height: 45px;
          }
          @media screen and (min-width: ${breakpoint.xl}) {
            font-size: 55px;
            line-height: 55px;
          }
        }
        .priceTextFree {
          font-weight: 600;
          font-size: 55px;
          line-height: 55px;

          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 45px;
            line-height: 45px;
          }
          @media screen and (min-width: ${breakpoint.xl}) {
            font-size: 55px;
            line-height: 55px;
          }
        }
      }
      .priceBilledRender {
        visibility: hidden;
      }
    }
  }
`

const PerMonth = styled.span``

const Billed = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: ${color.yellow};
  margin-top: 5px;
  min-height: 25px;

  .lifetime {
    color: ${color.yellow};
    font-weight: 700;
  }
`

const SwitchPeriodWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
`

const PriceSwitchTextWrapper = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  text-align: center;

  .darkText {
    color: ${color.white};
  }

  .blueText {
    color: ${color.blueInfo};
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 5px;
    margin-left: 3px;
    position: relative;
    cursor: pointer;

    :hover > .tooltip {
      visibility: visible;
    }
  }

  .and {
    margin-left: 3px;
    color: ${color.white};
  }
`

/* -------------------------------------------------- Card Bottom -------------------------------------------------- */
const BottomWrapper = styled.div``

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11px 0;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 13px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 14px;
    }
  }

  .blueButton {
    font-weight: bold;
    font-size: 16px;
    background: ${color.yellow};
    color: ${color.font};
    transition: ${transition.default};

    &:hover {
      /* background: #0f50b9; */
      color: ${color.white};
    }
  }

  button {
    cursor: pointer;
  }
`

const FeaturesWrapper = styled.div`
  .featureTitle {
    font-weight: bold;
    font-size: 16px;
    color: ${color.white};
    margin: 20px 0;
  }
`

const Feature = styled.div`
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 13px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 14px;
  }

  .enabledFeature,
  .disabledFeature {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;

    img {
      margin-right: 8px;
    }
  }

  .enabledFeature {
    position: relative;
    cursor: pointer;

    :hover > .tooltip {
      visibility: visible;
    }

    .aiFeature {
      font-weight: bold;

      .featureName {
        margin: 0 5px;
      }
    }

    .featureText {
      color: ${color.white};

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 25px);
        height: 1px;
        left: 25px;
        bottom: -4px;
        border-top: 1px dashed rgb(147, 161, 176);
      }

      .aIRequestsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .aiIcon {
      margin: 3px 0 0;
    }
  }

  .disabledFeature {
    .featureText {
      color: #bbc9e4;
    }
  }
`

const SaveUp = styled.div`
  width: 100%;
  font-family: 'Caveat Brush', cursive;
  font-size: 20px;
  text-align: center;
  color: ${color.yellow};
`
