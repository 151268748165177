import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import aiAssistanceBackground from '@assets/images/aiAssistance/aiAssistanceBackground.png'
import aiAssistanceIcon from '@assets/images/aiAssistance/aiAssistanceIcon.svg'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import UnderlineLink from '@components/common/UnderlineLink'
import { RegilarText } from '@components/ui/typography/Typography'
const AiAssistanceSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      aiAssistance: file(relativePath: { eq: "images/aiAssistance/aiAssistance.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 450
            placeholder: NONE
            formats: [AUTO, WEBP]
            breakpoints: [320, 768, 1380]
          )
        }
      }
    }
  `)
  return (
    <SectionWrapper>
      <Wrapper>
        <TextWrapper>
          <div className="icon">
            <img src={aiAssistanceIcon} alt="aiAssistanceIcon" />
            <span>{intl.formatMessage({ id: 'header.product-list.ai-assistance' })}</span>
          </div>
          <div className="title">{intl.formatMessage({ id: 'home.aiAssistance.title' })}</div>
          <div className="text">
            <RegilarText>{intl.formatMessage({ id: 'home.aiAssistance.text' })}</RegilarText>
          </div>
          <LinkWrapper>
            <UnderlineLink
              to="/features/ai-assistance/"
              text={intl.formatMessage({ id: 'home.features.learnMore' })}
              color={color.ai}
            />
          </LinkWrapper>
        </TextWrapper>
        <ImageWrapper>
          <GatsbyImage image={data.aiAssistance.childImageSharp.gatsbyImageData} alt="advertising" />
        </ImageWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(AiAssistanceSection)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  background-image: url(${aiAssistanceBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    background-size: contain;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    justify-content: space-between;
    padding: 30px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  padding: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 500px;
    padding: 20px 50px 20px 70px;
  }

  .icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    color: ${color.ai};
    font-weight: bold;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    margin: 15px 0 40px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
  }
`

const LinkWrapper = styled.div`
  margin-top: 30px;
`
