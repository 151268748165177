import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import LifetimePlanCards from '@components/pages/lifetime-deal/LifetimePlanCards'
import YourCountry from '@components/common/YourCountry'
import backgroundLifetime from '@assets/images/lifetime/backgroundLifetime.svg'
const LifetimeCardSection = () => {
  return (
    <Wrapper>
      <SectionWrapper>
        <LifetimePlanCards period={'lifetime'} />
        <YourCountry />
      </SectionWrapper>
    </Wrapper>
  )
}

export default LifetimeCardSection

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    background-image: url(${backgroundLifetime});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`
