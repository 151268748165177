import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import { MainHeroHeadingH1, DescriptionDisplay1 } from '@components/ui/typography/Typography'

const LifetimeHeroSection = ({ intl }) => {
  return (
    <SectionWrapper sectionBackground="#ffffff">
      <Wrapper>
        <MainHeroHeadingH1>{intl.formatMessage({ id: 'lifetime-deal.hero.title' })}</MainHeroHeadingH1>
        <DescriptionWrapper>
          <DescriptionDisplay1>{intl.formatMessage({ id: 'lifetime-deal.hero.description' })}</DescriptionDisplay1>
        </DescriptionWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(LifetimeHeroSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 600px;
`
