import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint, transition, opacity } from '@configs/utilities'
import shieldCheck from '@assets/images/pricing/heroBottom/shield-check.png'
import paddle from '@assets/images/pricing/heroBottom/paddle.png'
import american from '@assets/images/pricing/heroBottom/american.png'
import mastercard from '@assets/images/pricing/heroBottom/mastercard.png'
import paypal from '@assets/images/pricing/heroBottom/paypal.png'
import visa from '@assets/images/pricing/heroBottom/visa.png'
import moneyBack from '@assets/images/pricing/heroBottom/moneyBack30.svg'
import { OpenModalContext } from '@components/layouts/light/LayoutLightLifetime'
import SectionWrapperFlexible from '@components/ui/wrappers/SectionWrapperFlexible'
import modalContactTypes from '@configs/modalContactTypes'

const LifetimeMoneyBackSection = ({ intl, sectionPaddingSm, sectionPaddingMd, sectionPaddingXl }) => {
  return (
    <SectionWrapperFlexible
      sectionPaddingSm={sectionPaddingSm}
      sectionPaddingMd={sectionPaddingMd}
      sectionPaddingXl={sectionPaddingXl}
    >
      <Wrapper>
        <Payment>
          <PaymentTitle>
            <img className="shield-check" src={shieldCheck} alt="shield-check" width={14} />
            <span className="text">{intl.formatMessage({ id: 'pricing.hero.securePayments' })}</span>
            <img className="paddle" src={paddle} alt="paddle" width={92} />
          </PaymentTitle>
          <PaymentCards>
            <img src={paypal} alt="payment-paypal" width={66} />
            <img src={visa} alt="payment-visa" width={66} />
            <img src={mastercard} alt="payment-mastercard" width={66} />
            <img src={american} alt="payment-american" width={66} />
          </PaymentCards>
        </Payment>
        <MoneyBack>
          <MoneyBackImageWrapper>
            <img src={moneyBack} alt="payment-moneyBack" width={99} />
          </MoneyBackImageWrapper>
          <MoneyBackTextWrapper>
            <p className="title">{intl.formatMessage({ id: 'pricing.hero.lifetimeMoneyBackTitle' })}</p>
            <p className="text">
              <span>{intl.formatMessage({ id: 'pricing.hero.lifetimeMoneyBackTextTop' })}</span>
              <OpenModalContext.Consumer>
                {(value) => {
                  return (
                    <Mail
                      onClick={() => {
                        value.openContact(modalContactTypes.CONTACT)
                      }}
                      aria-hidden="true"
                    >
                      {intl.formatMessage({ id: 'pricing.hero.moneyBackTextMail' })}
                    </Mail>
                  )
                }}
              </OpenModalContext.Consumer>
              <span>{intl.formatMessage({ id: 'pricing.hero.moneyBackTextBottom' })}</span>
            </p>
          </MoneyBackTextWrapper>
        </MoneyBack>
      </Wrapper>
    </SectionWrapperFlexible>
  )
}

export default injectIntl(LifetimeMoneyBackSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${color.font};
  row-gap: 40px;
`

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`

const PaymentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  .shield-check {
    width: 12px;

    @media screen and (min-width: ${breakpoint.md}) {
      width: 14px;
    }
  }

  .text {
    width: 100%;
    font-weight: 300;
    font-size: 12px;
    margin: 0 4px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 14px;
      margin: 0 6px;
    }
  }

  .paddle {
    width: 78px;
    margin-bottom: 5px;

    @media screen and (min-width: ${breakpoint.md}) {
      width: 92px;
    }
  }
`

const PaymentCards = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoint.md}) {
    min-width: 330px;
  }

  img {
    width: 56px;

    @media screen and (min-width: ${breakpoint.md}) {
      width: 66px;
    }
  }
`

const MoneyBack = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px solid #0e64e6;
  background: ${color.blueBackground};
  padding: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
  }
`

const MoneyBackImageWrapper = styled.div`
  width: 100px;
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoint.md}) {
    margin-bottom: 0;
  }
`

const MoneyBackTextWrapper = styled.div`
  text-align: center;
  max-width: 550px;

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 560px;
    text-align: left;
    margin-left: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 10px;
  }
  .text {
    font-weight: 300;
    font-size: 16px;
  }
`

const Mail = styled.span`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  color: ${color.blue};
  margin: 0 3px;
  transition: ${transition.default};

  :hover {
    opacity: ${opacity.default};
  }
`
