import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-intl'
import Headroom from 'react-headroom'
import { ContentWrapper } from '@components/ui/wrappers/Wrappers'
import { GlobalPadding } from '@components/ui/wrappers/Wrappers'
import { color, breakpoint, transition, opacity } from '@configs/utilities'
import logo from '@assets/images/navbar/ib-logo.svg'
import mobileLogo from '@assets/images/navbar/mobile-logo.svg'
import ButtonsNavbar from '@components/ui/buttons/ButtonsNavbar'
import { injectIntl } from 'gatsby-plugin-react-intl'
import LanguageSelector from '@components/common/LanguageSelector'
import { graphql, useStaticQuery } from 'gatsby'
import _find from 'lodash/find'
import { generateAppLink } from '@configs/helpers'

const Navbar = ({ intl, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      allNavbarLightJson {
        edges {
          node {
            slug
            external
            href
            buttonText
          }
        }
      }
    }
  `)

  const navData = data.allNavbarLightJson.edges.map((item) => {
    return item.node
  })

  const pageData = _find(navData, ['slug', slug])

  const defaultData = {
    external: true,
    href: 'signup',
    buttonText: 'buttons.tryForFree',
  }

  const finalData = pageData ? pageData : defaultData

  return (
    <Headroom style={{ zIndex: 9 }}>
      <NavWrapper>
        <ContentWrapper>
          <GlobalPadding>
            <NavContent>
              <NavLogo>
                <Link to="/">
                  <img src={logo} alt="IdeaBuddy Logo" className="deskLogo" />
                  <img src={mobileLogo} alt="IdeaBuddy Logo" className="mobLogo" />
                </Link>
              </NavLogo>
              <ButtonWrapper>
                <LanguageSelectorWrapperDesktop>
                  <LanguageSelector />
                </LanguageSelectorWrapperDesktop>
                <LanguageSelectorWrapperMobile>
                  <LanguageSelector mobile={true} />
                </LanguageSelectorWrapperMobile>
                <ButtonsNavbar
                  external={finalData.external}
                  href={generateAppLink(finalData.href, intl.locale)}
                  // to="/pricing"
                  buttonText={intl.formatMessage({ id: finalData.buttonText })}
                  mdfontsize={'14px'}
                />
              </ButtonWrapper>
            </NavContent>
          </GlobalPadding>
        </ContentWrapper>
      </NavWrapper>
    </Headroom>
  )
}

export default injectIntl(Navbar)

const NavWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${color.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
`

const NavContent = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  background: ${color.white};
  justify-content: space-between;

  @media screen and (min-width: ${breakpoint.lg}) {
    min-height: 80px;
  }
  @media screen and (min-width: ${breakpoint.lg}) {
    padding-bottom: 7px;
  }
`

const NavLogo = styled.div`
  margin-top: 10px;
  width: 40px;
  transition: ${transition.default};
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoint.lg}) {
    width: 150px;
    margin-bottom: 5px;

    &:hover {
      opacity: ${opacity.default};
    }
  }

  .deskLogo {
    display: none;
    @media screen and (min-width: ${breakpoint.lg}) {
      display: flex;
    }
  }

  .mobLogo {
    display: flex;
    @media screen and (min-width: ${breakpoint.lg}) {
      display: none;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;

  @media screen and (min-width: ${breakpoint.lg}) {
    margin-bottom: 5px;
  }
`

const LanguageSelectorWrapperDesktop = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoint.lg}) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
`

const LanguageSelectorWrapperMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media screen and (min-width: ${breakpoint.lg}) {
    display: none;
  }
`
